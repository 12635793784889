@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;900&display=swap');


body {
    font-family: 'Roboto', sans-serif;
    font-size: 12px;
    scroll-behavior: smooth;
    overflow-x: hidden;
}

.custom_bg {
    background-color: #0496D1;
}

.custom_container {
    padding: 0 100px;
    margin: 0 auto;
    position: relative;

}



/* animation play button */
.animation {
    background-color: aqua;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.animation::after {
    content: '';
    position: absolute;
    border: 15px solid #ffff;
    border-radius: 50%;
    animation: bloom 1.5s linear infinite;
    opacity: 0;
}


@keyframes bloom {
    0% {
        transform: scale(.5);
    }

    50% {
        opacity: 1;
    }

    100% {
        transform: scale(2.3);
    }

}

.sentBtn {
    background-color: #0496D1;
}

.sentBtn:hover {
    background-color: #0496D1;
    opacity: 50%;
}

/* 
.custom_design img {
    filter: grayscale(100%);
}

.custom_design img:hover {
    filter: grayscale(0%);
} */


@media only screen and (max-width: 600px) {
    .custom_container {
        padding: 0 20px;
        margin: 0 auto;
        position: relative;
    }

}

@media only screen and (min-width: 1200px) {
    .custom_container {
        width: 1200px;
        left: 0;
        right: 0;
        padding: 0;
    }

}